import React, { useEffect } from 'react'
import axios from 'axios';
import ApiUrl from 'src/utils';

const Access = () => {

  useEffect(() => {
    const token = window.location.hash.replace('#/access?token=', '');
      if (!token) {
        location.href = "/#/login";
      }
      const config = {
        headers: { Authorization: 'Bearer ' + token }
      };
        axios.get(ApiUrl('user/me'), config).then(resp => {
            if (resp.data._id) {
              localStorage.setItem("app-jwt", token);
              location.href = "/";
            }
        }).catch((err) => {
          location.href = "/#/login";
        })
  })

  return (
  <>
    <p>Redirecting...</p>
  </>
)}

export default Access;
