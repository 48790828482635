require('dotenv').config();

const env = {
  "production_url": "https://api.togathr.io/",
  "staging_url": "https://togathr.herokuapp.com/",
  "local_url" : "http://localhost:8080/"
}

const ApiUrl = (route) => {
  if (localStorage.getItem("staging"))
    return env.staging_url + route;
  const url = process.env.NODE_ENV === 'production' ? env.production_url : env.local_url;
  return url + route;
}

export default ApiUrl
